<template>
  <div class="store">
    <div class="title">
      <div @click="goBack"><i class="el-icon-arrow-left"></i></div>

      <div>历史记录</div>
    </div>
    <div class="center">
      <div class="search">
        <div class="search-left">
          <div class="left-time">
            <van-field
              readonly
              clickable
              name="datetimePicker"
              :value="value"
              label=""
              placeholder="点击选择时间"
              @click="handleSelectDate"
            />
            <img class="selectIoc" src="~@/assets/img/list/selectIoc.png" />
          </div>
          <van-popup v-model="showPicker" position="bottom">
            <van-datetime-picker
              type="year-month"
              v-model="currentDate"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
        </div>
        <div class="search-right">总收入￥<span>{{ listData.totalMoney || 0 }}</span></div>
      </div>
      <div class="showHide" v-loading="loading">
        <ul class="list" v-if="type == 1">
          <li
            v-for="(item, idx) in listData.list"
            :key="idx"
            :class="[idxShow == idx ? 'liShow' : '']"
            @click="handleListData(item, idx)"
          >
            <div class="li-left">
              <div :class="[idxShow == idx ? 'left-show' : 'left-hide']">
                <div class="show-day">{{ item.day }}</div>
              </div>
            </div>
            <div :class="[idxShow == idx ? 'li-rightShow' : 'li-right']">
              <div :class="[idxShow == idx ? 'right-sumShow' : 'right-sum']">
                {{ item.day }}日共收入<span>+</span>{{ item.totalCapital }}
              </div>
              <div :class="[idxShow == idx ? 'right-showType' : 'right-type']">
                <div class="type-cash">现金：{{ item.cash }}</div>
                <div class="type-phone">移动支付：{{ item.mobile }}</div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="list" v-else>
          <li
            v-for="(item, idx) in listData.list"
            :key="idx"
            :class="[idxShow == idx ? 'liShow' : '']"
            @click="handleListData(item, idx)"
          >
            <div class="li-left">
              <div :class="[idxShow == idx ? 'left-show' : 'left-hide']">
                <div class="show-day">{{ item.day }}</div>
              </div>
            </div>
            <div :class="[idxShow == idx ? 'li-rightShow' : 'li-right']">
              <div class="right-all">
                <div :class="[idxShow == idx ? 'right-sumShow' : 'right-sum']">
                  {{ item.day }}日共收入<span>+</span>{{ item.totalRevenue }}
                </div>
                <div :class="[idxShow == idx ? 'right-showType' : 'right-type']">
                  <div class="type-cash">网费：{{ item.networkFee }}</div>
                  <div class="type-phone">商品：{{ item.sale }}</div>
                </div>

              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import { Picker, Field, Popup, DatetimePicker, Toast } from "vant";
export default {
  name: "store",
  data() {
    return {
      currentDate: new Date(),
      loading: true,
      value: "",
      showPicker: false,
      listData: {
        list: []
      },
      idxShow: 0,
      month: "",
      shopId: "",
      type: 1
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal) {
        if (this.type == 1) {
          this.handleFirstData();
        } else {
          this.handleTwoData();
        }
      }
    }
  },
  mounted() {
    this.type = this.$route.query.type;
    this.shopId = this.$route.query.shopId || localStorage.shopId;
    this.value = this.parseTime(new Date(), "{y}-{m}");
  },
  methods: {
    // 每月营收列表
    handleTwoData() {
      let dataTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let _this = this;
      _this.loading = true

      _this.$API
        .getRevenueList({ shopId: _this.shopId, month: _this.value })
        .then(function(res) {
          if (res.code == 200) {
            _this.loading = false
            _this.listData = res.data;
            let list = _this.listData.list;
            let day;
            for (let i in list) {
              day = _this.parseTime(new Date(list[i].time), "{d}");
              list[i].day = day;
            }
            _this.listData.list = list;
          } else {
            Toast(res.msg);
          }
        });
    },
    handleFirstData() {
      let dataTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let _this = this;
      _this.loading = true
      _this.$API
        .getHistoricalIncome({ shopId: _this.shopId, month: _this.value })
        .then(function(res) {
          if (res.code == 200) {
            _this.loading = false
            _this.listData = res.data;
            let list = _this.listData.list;
            let day;
            for (let i in list) {
              day = _this.parseTime(new Date(list[i].time), "{d}");
              list[i].day = day;
            }
            _this.listData.list = list;
          } else {
            Toast(res.msg);
          }
        });
    },
    handleSelectDate() {
      this.showPicker = true;

    },
    changeFn() {
      // 值变化是触发
      this.value = this.value; // Tue Sep 08 2020 00:00:00 GMT+0800 (中国标准时间)
    },
    onConfirm(time) {
      let dataTime = this.parseTime(new Date(time), "{y}-{m}");
      this.value = dataTime;
      this.showPicker = false;
    },
    goBack() {
      this.$router.go(-1);
    },

    handleListData(res, idx) {
      this.idxShow = idx;
      if (this.type == 1) {
        this.$router.push({
          name: "Income",
          query: { dateTime: this.value, day: res.day }
        });
      } else {
        this.$router.push({
          name: "Revenue",
          query: { dateTime: this.value, day: res.day }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.store {
  .title {
    display: flex;
    padding: 20px;
    align-items: center;
    i {
      font-size: 30px;
    }
    > div:nth-child(2) {
      flex: 5;
      text-align: center;
      font-size: 38px;
    }
  }
  .center {
    margin-top: 41px;

    .NO{
      margin-top: 60px;
      color: #666666;
      font-size: 28px;
      text-align: center;
    }
    .search {
      display: flex;
      align-items: center;
      padding: 0 30px;
      .search-left {
        flex: 5;
        font-size: 26px;
        color: #989eb3;
        .left-time {
          display: flex;
          align-items: center;
          img {
            width: 21px;
            height: 17px;
          }
        }
        /deep/.van-cell {
          width: 150px;
          .van-field__control {
            max-width: 100%;
            color: #989eb3;
          }
        }
      }
      .search-right {
        flex: 5;
        text-align: right;
        font-size: 28px;
        font-weight: bold;
        >span{
          color: #FD2F34;
        }
      }
    }
    .list {
      margin: 30px 0;
      .liShow {
        height: 150px;
        background: url("~@/assets/img/list/listBg3.png");
        background-size: 100% 100%;
      }
      li {
        display: flex;
        // align-items: center;
        padding-left:30px;
        .li-left {
          width: 119px;
          font-size: 33px;
          color: #5698EC;
          .left-hide {
            position: relative;
            margin: auto;
            margin-top: 30%;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            text-align: center;
            line-height: 60px;
            background: rgba(86, 152, 236, 0.04);
          }
          .left-show {
            position: relative;
            width: 100%;
            height: 119px;
            text-align: center;
            line-height: 119px;
            background: url("~@/assets/img/list/headerBg.png");
            background-size: 100% 100%;
          }
          .show-day {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 45%;
            transform: translate(-50%, -50%);
            z-index: 33;
            font-family: PingFangSC-Semibold, sans-serif;
          }
        }
        .li-rightShow {
          flex: 4;
          margin-left: 28px;
          padding-top: 15px;
          padding-right: 30px;
          .right-all{
            padding-left: 10px;
          }
          .right-sum {
            color: #000000;
            font-size: 28px;
            font-weight: bold;
            span {
              // font-size: 28px;
            }
          }
          .right-sumShow {
            color: #ffffff;
            font-size: 28px;
            font-weight: bold;
            span {
              font-size: 15px;
            }
          }
          .right-showType {
            display: flex;
            color: #ffffff;
            font-size: 24px;
            margin-top: 18px;
            .type-cash {
              flex: 4;
            }
            .type-phone {
              text-align: right;
              flex: 6;
              margin-left: auto;
            }
          }
          .right-type {
            display: flex;
            color: #666666;
            font-size: 24px;
            margin-top: 18px;
            .type-cash {
              flex: 4;
            }
            .type-phone {
              text-align: right;
              flex: 6;
              margin-left: auto;
            }
          }
        }
        .li-right {
          flex: 4;
          margin-left: 28px;
          padding-top: 30px;
          padding-bottom: 30px;
          padding-right: 30px;
          border-bottom: 1px solid #e7e9ec;
          .right-all{
            padding-left: 10px;
          }
          .right-sum {
            color: #000000;
            font-size: 28px;
            font-weight: bold;
            span {
              font-size: 15px;
            }
          }
          .right-sumShow {
            color: #ffffff;
            font-size: 28px;
            font-weight: bold;
            span {
              font-size: 15px;
            }
          }
          .right-showType {
            display: flex;
            color: #ffffff;
            font-size: 22px;
            margin-top: 18px;
            .type-cash {
              flex: 4;
            }
            .type-phone {
              text-align: right;
              flex: 6;
              margin-left: auto;
            }
          }
          .right-type {
            display: flex;
            color: #666666;
            font-size: 22px;
            margin-top: 18px;
            .type-cash {
              flex: 4;
            }
            .type-phone {
              text-align: right;
              flex: 6;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
</style>
